import axios from '@/libs/axios'

export function reqGetTransactions(payload) {
  return axios.get('api/transactions', { params: payload })
}
export function reqGetTransaction(id) {
  return axios.get(`api/transactions/${id}`)
}
export function reqPayTransaction(id, payload) {
  return axios.post(`api/transactions/${id}/pay`, payload)
}
export function reqDeleteTransaction(id) {
  return axios.delete(`api/transactions/${id}`)
}
